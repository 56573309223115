*{
  padding:0;
  margin:0;
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
}
h2 {
  margin-bottom: 15px;
}
p{
  margin-bottom: 15px;
}
.container {
  max-width: 980px;
  margin: 0 auto;
}
header {
  height: 100px;
  background-color:azure;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
header .container h1 {
  font-size: 18px;
  color:gray;
}
header .container span {
  color:gray;
  font-size: 12px;
}
footer {
  height: 80px;
  background-color: #000;
}
footer .container{
  height: 100%;
  display: flex;
  align-items: center;
  color:#fff;
}
.content {
  padding: 50px 0;
}
.menu {
  width: 100%;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;

}
.menu li {
  flex:1;
}
.menu li a {
  display: block;
  text-decoration: none;
  color:#000;
  padding: 10px 20px;
  text-align: center;
}
.menu li a:hover,
.menu li a.active {
  background-color:aquamarine;
}